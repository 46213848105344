import SALARY_STATUS from './../constants/SalaryStatus.constant';

const initialState = {
    getLoading: false,
    salaryStatus: [],
    salaryStatusMetaData: {},

    getSalaryStatusDetailLoading: false,
    downloadSalaryStatusDetailLoading: false,

    searchEmployeesTransactionList: [],
    searchEmployeesTransactionListMetaData: {},
    searchEmployeesTransactionListLoading: false,
};

export default (state = initialState, action = {}) => {
    switch (action.type) {

        case SALARY_STATUS.GET_SALARY_STATUS:
            return {
                ...state,
                getLoading: action.loading,
                salaryStatus: action.data || state.salaryStatus,
                salaryStatusMetaData: action.metaData || state.salaryStatusMetaData
            };

        case SALARY_STATUS.GET_SALARY_STATUS_DETAIL:
            return {
                ...state,
                getSalaryStatusDetailLoading: action.loading,
            };

        case SALARY_STATUS.DOWNLOAD_SALARY_STATUS_DETAIL:
            return {
                ...state,
                downloadSalaryStatusDetailLoading: action.loading,
            };

        case SALARY_STATUS.SEARCH_EMPLOYEES_TRANSACTION_LIST:
            return {
                ...state,
                searchEmployeesTransactionList: action.data||state.searchEmployeesTransactionList,
                searchEmployeesTransactionListMetaData: action.metaData || state.searchEmployeesTransactionListMetaData,
                searchEmployeesTransactionListLoading: action.loading,
            };

        default:
            return state;
    }
};
