import APPROVALS from "./../constants/Approvals.constant";
import { post } from "../../utils/apiMethods";
import { _readableTextCap, handleError, handleSuccess, openNotification } from "../../utils/methods";
import { CLIENT_APPROVAL_STATUS } from "../../utils/constants";

export const setApprovalCount = (count) => (dispatch) => {
  const dispatchType = APPROVALS.GET_APPROVAL_COUNT;
  dispatch({ type: dispatchType, data: count });
};
export const getApprovals = (payload) => (dispatch) => {
  dispatch({ type: APPROVALS.GET_APPROVALS, loading: true });
  post(`approvals/getAll`, payload)
    .then(({ data }) => {
      if (!data.error) {
        dispatch({
          type: APPROVALS.GET_APPROVALS,
          loading: false,
          data: data?.data?.entries,
          metaData: data?.data?.metaData,
        });
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: APPROVALS.GET_APPROVALS, loading: false, data: [], metaData: [] });
      }
    })
    .catch((error) => {
      dispatch({ type: APPROVALS.GET_APPROVALS, loading: false, data: [], metaData: [] });
      handleError(
        error?.response?.data?.message ||
        error?.data?.message ||
        "Something went wrong!"
      );
    });
};
export const approveOrReject = (payload, CB) => async (dispatch) => {
  dispatch({ type: APPROVALS.APPROVAL_APPROVE_REJECT, loading: true });
  post(`approvals/takeAction`, payload)
    .then(({ data }) => {
      if (data?.error) {
        dispatch({ type: APPROVALS.APPROVAL_APPROVE_REJECT, loading: false });
        handleError(data?.data?.message || "Something went wrong!");
      } else {
        dispatch({ type: APPROVALS.APPROVAL_APPROVE_REJECT, loading: false });
        handleSuccess(
          data?.data?.message || "Successfully ApprovalConstant File Uploaded"
        );
        CB && CB();
      }
    })
    .catch((error) => {
      dispatch({ type: APPROVALS.APPROVAL_APPROVE_REJECT, loading: false });
      handleError(
        error?.response?.data?.message ||
        error?.data?.message ||
        "Something went wrong!"
      );
    });
};

const getApprovalText = (data = {}, isNew = false) => {
  if (isNew) {
    return `Approval Id: ${data?.approvalId} created by ${data?.createdBy?.user?.companyName || data?.createdBy?.user?.name} of ${_readableTextCap(data?.topic)}`
  } else {
    let approvalTrackingStatus = data?.approvalTrackingStatus;
    if (data?.approvalTrackingStatus === CLIENT_APPROVAL_STATUS.APPROVED_BY_AUTHORIZER) {
      approvalTrackingStatus = 'WAITING_FOR_AUTHORIZER';
    }
    return `Approval Id: ${data?.approvalId} is ${_readableTextCap(approvalTrackingStatus)} by ${(data?.approvalTracking && data?.approvalTracking?.length && data?.approvalTracking[data?.approvalTracking?.length - 1]?.approvedBy) ? data?.approvalTracking[data?.approvalTracking?.length - 1]?.approvedBy?.companyName || data?.approvalTracking[data?.approvalTracking?.length - 1]?.approvedBy?.name : ''} of topic ${_readableTextCap(data?.topic)}`
  }
}

export const updateApproval = (response, statuses = [], includedTopics = [], reduxState = '') => async (dispatch, getState) => {
  let data = response?.approval;
  let isNew = response?.isNew;
  let selectedClient = reduxState?.selectedClient;
  let user = reduxState?.user
  const previousApprovals = getState()?.approval?.approvals;
  if (((!user?.isClient && user?.client?._id === data?.client?._id) || selectedClient && selectedClient?.length && selectedClient === data?.client?._id) || (!selectedClient && user?._id === data?.client?._id)) {
    if (!isNew) {
      openNotification('Approval updated', getApprovalText(data))
    }
    if (previousApprovals?.length && previousApprovals?.some(item => item?.approvalId === data?.approvalId)) {
      const foundedIndex = previousApprovals?.findIndex((item) => item?.approvalId === data?.approvalId);
      if (foundedIndex !== -1) {
        let newArray = previousApprovals;
        if (statuses?.length) {
          if (statuses?.includes(data?.approvalTrackingStatus)) {
            newArray = [...newArray.slice(0, foundedIndex), data, ...newArray.slice(foundedIndex + 1)];
            // dispatch({ type: APPROVALS.UPDATE_APPROVAL_SOCKET, data: newArray });
          } else {
            newArray = previousApprovals.filter(approval => approval?.approvalId !== data?.approvalId);
            // dispatch({ type: APPROVALS.UPDATE_APPROVAL_SOCKET, data: newArray });
          }
        } else {
          newArray = [...newArray.slice(0, foundedIndex), data, ...newArray.slice(foundedIndex + 1)];
          // dispatch({ type: APPROVALS.UPDATE_APPROVAL_SOCKET, data: newArray });  
        }
        dispatch({ type: APPROVALS.UPDATE_APPROVAL_SOCKET, data: newArray });
      }

    } else {
      if (isNew) {
        if (!statuses?.length || (statuses?.length && statuses?.includes(data?.approvalTrackingStatus))) {
          let includesTopics = includedTopics?.length ? includedTopics : user?.threeStepVerification;
          if (includesTopics?.length && includesTopics?.includes(data?.topic)) {
            let newArray = [data, ...previousApprovals];
            if (newArray?.length > 10) {
              newArray = newArray.sort((a, b) => b?.approvalId - a?.approvalId);
              newArray.pop();
            }
            openNotification('New Approval created', getApprovalText(data, isNew))
            dispatch({ type: APPROVALS.UPDATE_APPROVAL_SOCKET, data: newArray });
          }
        }
      }
    }
  }
}

export const getOTP = (CB) => async (dispatch) => {
  const dispatchType = APPROVALS.GET_OTP;
  dispatch({ type: dispatchType, loading: true });
  post(`generic/sendOTP`)
    .then(({ data }) => {
      if (data?.error) {
        dispatch({ type: dispatchType, loading: false });
        handleError(data?.data?.message || "Something went wrong!");
      } else {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.message || "OTP sent successfully!");
        CB && CB();
      }
    }).catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};