import React from "react";
import { Field } from "formik";
import CIcon from "../../assets/cIcon/CIcon";
import CLoading from "../cLoading/CLoading";
import InputMask from "react-input-mask";
import { Checkbox, DatePicker, Input, Radio, Select } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import moment from "dayjs";
import { MappedElement } from "../../utils/methods";
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const CField = (props) => {
  const {
    error,
    touched,
    name,
    id,
    type,
    className,
    isPassword,
    containerClass = "",
    placeholder,
    hideInputPlaceholder = false,
    placeholderHide = false,
    data,
    leftIcon,
    rightIcon,
    customField,
    fieldType,
    checkboxLabel = "",
    value = "",
    onChange = () => null,
    as = "input",
    min = "",
    max = "",
    loading = false,
    disabled = false,
    onBlur = () => null,
    component,
    svgRight = undefined,
    svg = undefined,
    onClick = () => null,
    onIconClick = () => null,
    mask = "",
    prefixVal = "",
    onPrefixChange = () => null,
    disableDate = "",
    format = "",
    customOption = null,
    isPrefix = false,
    clear = false,
    isLabel = '',
    dropdownRender = undefined,
    renderSelectedKey = "name",
    valueKey = '',
    picker = "",
    maxLength = 0,
    allowClear = true,
    customSelectOption = null,
    mode = null,
    onDeselect = undefined,
    isShow = true,
    getObj = false,
    prefix = null,
    autoSize = null,
    autoFocus = false,
  } = props;

  let SVG = svg;

  const renderOTP = () => {
    return <div className={className || ""}>{customField()}</div>;
  };

  const renderFlagOptions = (options, key) => {
    if (options && options.length) {
      return options.map((option, index) => (
        <Select.Option
          key={index}
          value={option?.iso}
          data-thumbnail={require("../../assets/images/add.png")}
        >
          <div>
            {/* <img src={option?.flag} width='20' height={20} /> */}
            {option?.code}
          </div>
        </Select.Option>
      ));
    }
    return null;
  };

  const renderOptions = (options, key, valueKey) => {
    if (options && options.length) {
      return options.map((option, index) => (
        <Select.Option
          key={index}
          value={valueKey ? option[valueKey] : option?._id || option?.establishmentId}
        >
          {key ? option[key] || "" : ""}
        </Select.Option>
      ));
    }
    return null;
  };

  const filterOption = (input, option) => {
    const visited = new Set(); // Track visited nodes to prevent circular references

    const findStrings = (node) => {
      if (typeof node === 'string') {
        return [node];
      }
      if (Array.isArray(node)) {
        return node.flatMap(findStrings);
      }
      if (typeof node === 'object' && node !== null && !visited.has(node)) {
        visited.add(node);

        // Handle children if it's an array or object
        const childStrings = node.children
          ? Array.isArray(node.children)
            ? node.children.flatMap(findStrings)
            : findStrings(node.children)
          : [];

        // Handle props if it's an object
        const propStrings = node.props && typeof node.props === 'object'
          ? findStrings(node.props)
          : [];

        return [...childStrings, ...propStrings];
      }
      return [];
    };

    // Collect all strings from `children` and `props`
    const allStrings = findStrings(option);

    // Check if any of the found strings contain the input value
    return allStrings.some((str) => str.toLowerCase().includes(input.toLowerCase()));
  };

  const renderSelect = () => {
    return (
      <Select
        placeholder={placeholder}
        showSearch
        id={name}
        disabled={disabled}
        className={mode ? "multi" : ``}
        name={name}
        {...(mode && { mode })}
        {...(onDeselect && { onDeselect })}
        value={value}
        onSelect={(val) => onChange(getObj ? data?.find(item => item[valueKey] === val) : val)}
        {...(dropdownRender && { dropdownRender: dropdownRender })}
        filterOption={filterOption}
        loading={loading}
      >
        {placeholderHide ? null : (
          <Select.Option value={""}>{placeholder}</Select.Option>
        )}
        {customSelectOption
          ? customSelectOption(data, renderSelectedKey, valueKey)
          : renderOptions(data, renderSelectedKey, valueKey)}
      </Select>
    );
  };

  const renderCheckBox = (label) => {
    return (
      <Checkbox
        id={name}
        className="c-checkbox"
        onChange={onChange}
        disabled={disabled || loading}
        checked={value}
        name={name}
      >
        {checkboxLabel}
      </Checkbox>
    );
  };

  const renderRadio = () => {
    return (
      <Radio.Group
        id={name}
        name={name}
        className="c-checkbox"
        onChange={onChange}
        disabled={disabled || loading}
        value={value}
      >
        <MappedElement
          data={data}
          renderElement={(item, i) => <Radio key={i} value={item[valueKey]} className={className} >
            {item[renderSelectedKey]}
          </Radio>}
        />

      </Radio.Group>
    );
  };

  const renderPrefix = () => {
    return (
      <>
        <Select
          id={name}
          disabled={disabled || name === 'phone' || loading}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option?.children?.props
              ? option?.children?.props?.children?.includes(
                input?.toLowerCase()
              )
              : option?.children?.toLowerCase()?.includes(input?.toLowerCase())
          }
          className={`prefix ${value === "" ? "placeholder" : ""} ${loading ? "hide" : ""
            }`}
          name={name}
          value={prefixVal}
          onSelect={onPrefixChange}
        >
          {customOption ? customOption(data) : renderFlagOptions(data)}
        </Select>
        {loading ? <CLoading size="default" className="loading" /> : null}
      </>
    );
  };

  const renderMaskInput = () => {
    return (
      <div className="prefix">
        {renderPrefix()}
        <InputMask
          id={name}
          mask={mask}
          placeholder={placeholder}
          alwaysShowMask={false}
          value={value}
          onChange={onChange}
        />
      </div>
    );
  };

  const renderDatePicker = () => {
    return !clear ? (
      <DatePicker
        id={id || name}
        disabled={disabled || loading}
        value={value}
        onChange={onChange}
        {...(!hideInputPlaceholder && {placeholder: placeholder} )}
        name={name}
        {...(picker && { picker: picker })}
        allowClear={allowClear}
        disabledDate={disableDate}
        format={format}
      />
    ) : (
      <div className="clear-text">{moment(value).format("DD-MMM-YYYY")}</div>
    );
  };

  const renderPasswordField = () => {
    return (
      <Input.Password
        id={name}
        // {...props}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        disabled={disabled || loading}
        placeholder={placeholder}
        name={name}
        iconRender={(visible) =>
          visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
        }
      />
    );
  };

  const renderTextArea = () => {
    return (
      <TextArea
        id={name}
        onBlur={onBlur}
        // {...props}
        onChange={onChange}
        value={value}
        disabled={disabled || loading}
        placeholder={placeholder}
        autoSize={autoSize ? autoSize : {
          minRows: 4,
          maxRows: 4
        }}
        name={name}
        iconRender={(visible) =>
          visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
        }
      />
    );
  };

  const renderRangePicker = () => {
    return (
      <RangePicker
        id={name}
        {...props}
        placeholder={['Start Date', 'End Date']}
        format={format || "DD-MMM-YYYY"}
        size="large"
        onChange={onChange}
        value={value}
      />
    );
  };

  const renderData = () => {
    return fieldType === "select" ? (
      renderSelect()
    ) : fieldType === "mask" ? (
      renderMaskInput()
    ) : fieldType === "date" ? (
      renderDatePicker()
    ) : fieldType === "password" ? (
      renderPasswordField()
    ) : fieldType === "textArea" ? (
      renderTextArea()
    ) : fieldType === "range" ? (
      renderRangePicker()
    ) : fieldType === "radio" ? (
      renderRadio()
    ) : (
      <>
        {!clear ? (
          <Input
            id={name}
            autoFocus={autoFocus}
            {...(maxLength && { maxLength: maxLength })}
            onBlur={onBlur}
            // {...props}
            onChange={onChange}
            as={as}
            value={value}
            disabled={disabled || loading}
            placeholder={placeholder}
            name={name}
            type={type}
            min={min}
            max={max}
            prefix={prefix}
            component={component}
            suffix={
              rightIcon ? (
                typeof rightIcon === "string" ? (
                  <CIcon
                    className="c-field-left-icon"
                    icon={rightIcon}
                    onClick={onIconClick}
                  />
                ) : (
                  rightIcon("c-field-left-icon")
                )
              ) : svgRight ? (
                <img
                  className="c-field-left-icon"
                  src={svgRight}
                  alt={`icon`}
                  onClick={onIconClick}
                />
              ) : null
            }
          />
        ) : (
          <div className="clear-text">{value}</div>
        )}
      </>
    );
  };

  return isShow ? (
    <div
      className={`c-field-container ${containerClass} ${fieldType === "range" ? "rangePicker" : ""
        } `}
    >
      {customField ? (
        renderOTP()
      ) : fieldType === "checkbox" ? (
        renderCheckBox()
      ) : (
        <div
          className={`c-field ${prefix ? 'with-prefix' : ''} ${error ? "has-error" : ""} ${className || ""}`}
        >
          {leftIcon ? (
            <CIcon className="c-field-left-icon" icon={leftIcon} />
          ) : SVG ? (
            <SVG className="c-field-left-icon" />
          ) : null}
          {typeof isLabel === 'string' ? (placeholder?.length || isLabel?.length) ? (
            <div className="input-title">{isLabel || placeholder}</div>
          ) : null : null}
          {isPrefix ? (
            <div className="prefix">
              {renderPrefix()}
              {renderData()}
            </div>
          ) : (
            renderData()
          )}
        </div>
      )}
      {error ? <div className="error">{error}</div> : null}
    </div>
  ) : null;
};
export default CField;
