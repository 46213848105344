import APPROVALS from "../constants/Approvals.constant";

const initialState = {
  getLoading: true,
  approvals: [],
  metaData: {},
  approveRejectLoading: false,
  pendingApprovalsCount: 0,
  getOTPLoading: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case APPROVALS.GET_APPROVALS:
      return {
        ...state,
        getLoading: action.loading,
        approvals: action.data,
        metaData: action.metaData,
      };

    case APPROVALS.APPROVAL_APPROVE_REJECT:
      return {
        ...state,
        approveRejectLoading: action.loading,
      };
    case APPROVALS.GET_APPROVAL_COUNT:
      return {
        ...state,
        pendingApprovalsCount: action.data,
      };

    case APPROVALS.UPDATE_APPROVAL_SOCKET:
      return {
        ...state,
        approvals: action.data,
      };

    case APPROVALS.GET_OTP:
      return {
        ...state,
        getOTPLoading: action.loading,
      };

    default:
      return state;
  }
};
