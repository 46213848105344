import CommonConstant from "../constants/Common.constant";

const initialState = {
  isOpenLeftDrawer: false,

  currentCountry: {},

  countries: [],
  getCountriesLoading: false,

  states: [],
  getStatesLoading: false,

  allClients: [],
  getClientsLoading: false,

  selectedClient: "",

  downloadCSVLoading: false,

  topics: [],
  getTopicsLoading: false,

  isTimerOn: false,

  constants: {},
  getConstantsLoading: false,
  employerDocs: [],
  employeesDocs: [],

};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case CommonConstant.TOGGLE_LEFT_DRAWER:
      return { ...state, isOpenLeftDrawer: action.value };

    case CommonConstant.CURRENT_COUNTRY:
      return { ...state, currentCountry: action.country };

    case CommonConstant.GET_COUNTRIES:
      return {
        ...state,
        getCountriesLoading: action.loading,
        countries: action.data,
      };

    case CommonConstant.GET_STATES:
      return {
        ...state,
        getStatesLoading: action.loading,
        states: action.data,
      };

    case CommonConstant.GET_ALL_CLIENTS:
      return {
        ...state,
        getClientsLoading: action.loading,
        allClients: action.data,
      };

    case CommonConstant.SELECTED_CLIENT:
      return { ...state, selectedClient: action.data };

    case CommonConstant.DOWNLOAD_CSV:
      return { ...state, downloadCSVLoading: action.loading };

    case CommonConstant.GET_ALL_TOPICS:
      return {
        ...state,
        topics: action.data,
        getTopicsLoading: action.loading,
      };

    case CommonConstant.REFRESH_TOKEN_TIMER:
      return { ...state, isTimerOn: action.data };

    case CommonConstant.GET_CONSTANTS:
      return {
        ...state,
        constants: action.data,
        employerDocs: action.employerDocs,
        employeesDocs: action.employeesDocs,
        getConstantsLoading: action.loading,
      };

    default:
      return state;
  }
};
