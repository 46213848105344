import SalariesConstant from "../constants/Salaraies.constant";

const initialState = {
  uploadSalaryLoading: false,
  getTemplateLoading: false,
  uploadDepositSlipLoading: false,

  charges: [],
  getChargesLoading: false,

  getBrowserUploadLoading: false,

  linkedSalaries: [],
  getLinkedSalariesLoading: false,

  employee: {},
  searchLoading: false,
  processSalaryListLoading: false,

};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SalariesConstant.UPLOAD_SALARY_FILE:
      return { ...state, uploadSalaryLoading: action.loading };

    case SalariesConstant.GET_SALARY_TEMPLATE:
      return { ...state, getTemplateLoading: action.loading };

    case SalariesConstant.UPLOAD_DEPOSIT_SLIP:
      return { ...state, uploadDepositSlipLoading: action.loading };

    case SalariesConstant?.GET_CHARGES:
      return { ...state, charges: action.data, getChargesLoading: action.loading }

    case SalariesConstant?.BROWSE_UPLOAD:
      return { ...state, getBrowserUploadLoading: action.loading }

    case SalariesConstant.GET_LINKED_SALARIES:
      return { ...state, getLinkedSalariesLoading: action.loading, linkedSalaries: action.data }

    case SalariesConstant.SEARCH_EMPLOYEE:
      return {
        ...state,
        searchLoading: action.loading,
        employee: action.data
      }

    case SalariesConstant.PROCESS_SALARY_LIST:
      return { ...state, processSalaryListLoading: action.loading }

    default:
      return state;
  }
};
